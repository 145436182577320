:root {
  --primary: #072e5f;
  --dark_blue: #3d3f42;
  --grey: #7c7c7c;
  --dark-grey: #262729;
  --blue: #3157f7;
  --bcg-grey: #f7f8fa;
  --bcg-darker-grey: #898b8f;
  --white: #fff;
  --dark: #111111;
}

abbr,
address,
article,
aside,
audio,
b,
blockquote,
body,
body div,
caption,
cite,
code,
dd,
del,
details,
dfn,
dl,
dt,
em,
fieldset,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
p,
pre,
q,
samp,
section,
small,
span,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
ul,
var,
video {
  padding: 0;
  border: 0;
  margin: 0;
  background: transparent;
  font-size: 100%;
  font-weight: inherit;
  vertical-align: baseline;
}

html {
  font-size: 10px;
  scroll-behavior: smooth;
  user-select: none;

  @media screen and (min-width: 1440px) {
    font-size: 0.6944444444vw;
  }
  @media screen and (min-width: 1024px) and (min-aspect-ratio: 2 / 1) {
    font-size: 0.5902777778vw;
  }
}

* {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  background: #fff;
  color: $dark;
  font-family: "SourceSansPro";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  line-height: (18/14);
  -webkit-text-size-adjust: 100%;
}

ul {
  list-style: none;
}

.site-wrapper {
  overflow: hidden;
  width: 100%;
}

a,
button {
  &:focus {
    outline: none;
  }
}

.uppercase {
  text-transform: uppercase;
}

.button {
  position: relative;
  display: inline-block;
  padding: 11px 38px 13px;
  border: none;
  background-color: $blue-light;
  border-radius: 50px 0px 50px 50px;
  color: $white;
  font-size: 13px;
  font-weight: 600;
  line-height: (32/13);
  text-align: center;
  text-transform: uppercase;
  @include transition(0.3s ease-in-out);
  @include hover() {
    background-color: rgba($blue-light, 0.6);
  }
  &:active {
    background-color: rgba($blue-light, 1);
  }
  &.transparent {
    border: 1px solid $blue-light;
    background-color: transparent;
    @include hover() {
      border-color: rgba($blue-light, 0.6);
      background-color: transparent;
    }
    &:active {
      border-color: $blue-light;
      background-color: transparent;
    }
  }
}

svg,
img {
  max-width: 100%;
  height: auto;
}

.heading-1 {
  font-size: 44px;
  font-weight: 400;
  line-height: (68/44);
  text-transform: uppercase;
  @include media-breakpoint-down(xs) {
    font-size: 34px;
    line-height: (58/34);
  }
}

.heading-2 {
  font-size: 22px;
  font-weight: 600;
  line-height: (22/22);
  text-transform: uppercase;
}

.big-italic {
  font-size: 82px;
  font-weight: 200;
  line-height: (76/82);
  @include media-breakpoint-down(xs) {
    padding-left: 0;
    font-size: 52px;
    line-height: (46/52);
  }
}

.blue {
  color: $blue;
}

.white {
  color: $white;
}
