.relacje-inwestorskie {
    h1 {
        margin-bottom: 30px;
        font-family: "Formula Condensed", sans-serif;
        letter-spacing: 3px;
        text-transform: uppercase;
    }

    h2 {
        margin-top: 30px;
        margin-bottom: 10px;
        font-family: "Formula Condensed", sans-serif;
        letter-spacing: 3px;
        text-transform: uppercase;
    }
}

.relacje-inwestorskie-page {
    .navbar {
        //background-image: url(/public/images/bg.jpg);
        background-size: cover;
    }
}
