/*--- CUSTOM VARIABLES ---*/
$zindex-primary: 10;
/*------------------------*/

/*--- BOOTSTRAP VARIABLES ---*/
/*--- color system ---*/
$white: #fff;
$dark: #4d4d4f;
$blue: #34569a;
$blue-light: #558eff;
$orange: #fdb913;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-600: #6c757d;
$black: #000;
$green: #14451e;

$primary: #63a154;
/*--------------------*/

/*--- spacing ---*/
$spacer: 1rem;

/*---------------*/

/*--- body ---*/
$body-bg: $white;
$body-color: $white;
/*------------*/

/*--- links ---*/
$link-decoration: none;
$link-hover-decoration: none;
$link-color: $white;
$link-hover-color: $link-color;
/*-------------*/

/*--- paragraph ---*/
$paragraph-margin-bottom: 1rem;
/*-----------------*/

/*--- grid ---*/
$grid-breakpoints: (
  0: 0,
  xs: 576px,
  sm: 769px,
  md: 992px,
  lg: 1440px,
  xl: 1666px,
);

$grid-columns: 12;
$grid-gutter-width: 120px;
/*------------*/

/*--- components ---*/
$line-height-lg: 1.5;
$line-height-sm: 1.5;

$border-width: 1px;
$border-color: $gray-300;

$border-radius: 0;
$border-radius-lg: $border-radius;
$border-radius-sm: $border-radius;
/*------------------*/

/*--- typography ---*/
$font-family-sans-serif: "SourceSansPro";
$font-family-base: $font-family-sans-serif;
$font-family-secondary: "Formula Condensed", sans-serif;

$font-size-base: 1.125rem;
$font-size-lg: $font-size-base * 1.17;
$font-size-sm: $font-size-base * 0.875;

$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 500;
$font-weight-bold: 700;
$font-weight-bolder: bolder;

$font-weight-base: $font-weight-normal;
$line-height-base: 1.5;

$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 2;
$h3-font-size: $font-size-base * 1.75;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base;

$headings-margin-bottom: $spacer / 2;
$headings-font-family: $font-family-sans-serif;
$headings-font-weight: 500;
$headings-line-height: 1.2;
$headings-color: $white;

$text-muted: $gray-600;

$hr-border-color: rgba($black, 0.1);
$hr-border-width: $border-width;
/*----------------*/

/*--- buttons ---*/
$btn-border-radius: $border-radius;
$btn-border-radius-lg: $border-radius-lg;
$btn-border-radius-sm: $border-radius-sm;

$input-btn-padding-y: 1rem;
$input-btn-padding-x: 2rem;

$input-btn-padding-y-lg: 1.75rem;
$input-btn-padding-x-lg: 7rem;
/*---------------*/

/*--- input ---*/
$input-border-color: #dcdcdc;
$input-placeholder-color: $body-color;
/*-------------*/

/*--- z-index ---*/
$zindex-dropdown: 1000;
$zindex-sticky: 1020;
$zindex-fixed: 1030;
$zindex-modal-backdrop: 1040;
$zindex-modal: 1050;
$zindex-popover: 1060;
$zindex-tooltip: 1070;
/*---------------*/

/*--- alert ---*/
$alert-padding-y: 0.75rem;
$alert-padding-x: 1.25rem;
$alert-margin-bottom: 1rem;
$alert-border-radius: $border-radius;
$alert-link-font-weight: $font-weight-bold;
$alert-border-width: $border-width;

$alert-bg-level: -10;
$alert-border-level: -9;
$alert-color-level: 6;
/*-------------*/

/*--- modals ---*/
$breadcrumb-font-size: null;

$breadcrumb-padding-y: 0.75rem;
$breadcrumb-padding-x: 1rem;
$breadcrumb-item-padding: 0.5rem;

$breadcrumb-margin-bottom: 1rem;

$breadcrumb-bg: $gray-200;
$breadcrumb-divider-color: $gray-600;
$breadcrumb-active-color: $gray-600;
$breadcrumb-divider: quote("/");

$breadcrumb-border-radius: $border-radius;
/*--------------*/

/*--- close ---*/
$close-font-size: $font-size-base * 1.5;
$close-font-weight: $font-weight-bold;
$close-color: $black;
$close-text-shadow: 0 1px 0 $white;
/*-------------*/

/*---------------------------*/
