.privacy-policy {
  position: fixed;
  z-index: 8999;
  display: none;
  background: $primary;
  font-size: 0.8rem;
  transition: opacity 0.5s;
  @include media-breakpoint-up(sm) {
    font-size: 1rem;
  }

  &:not(.big) {
    right: 0;
    bottom: 0;
    left: 0;
    padding-top: 2vh;

    @include media-breakpoint-up(lg) {
      height: 5vw;
    }

    .text-content {
      display: none;
    }
  }

  &.big {
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    padding: 40px 0;
    overflow-x: hidden;
    overflow-y: scroll;
    .options {
      padding: 15px 0 30px;
    }
  }

  .description {
    display: flex;
    align-items: center;
  }

  .options {
    display: flex;
    align-items: center;
    .btn {
      padding: 1rem 1rem;
      @include media-breakpoint-up(sm) {
        padding: 1rem 2rem;
      }
    }
  }

  .text-content {
    h2 {
      font-size: 1.5rem;
      @include media-breakpoint-up(md) {
        font-size: 1.8rem;
      }
    }
    h3 {
      font-size: 1.2rem;
      @include media-breakpoint-up(md) {
        font-size: 1.5rem;
      }
    }
    h4 {
      font-size: 1.1rem;
      @include media-breakpoint-up(md) {
        font-size: 1.2rem;
      }
    }
    li {
      font-size: 0.8rem;
      @include media-breakpoint-up(md) {
        font-size: 1rem;
      }
    }
  }
}