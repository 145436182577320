.merch {
  position: relative;
  overflow: hidden;
  padding: 68px 0 48px;

  @include media-breakpoint-up(sm) {
    padding: 105px 0 40px;
  }

  @include media-breakpoint-up(xl) {
    padding-bottom: 160px;
  }

  .container {
    @include media-breakpoint-up(sm) {
      display: flex;
    }
  }

  &__left {
    position: relative;
    z-index: 10;

    @include media-breakpoint-up(xs) {
      padding-left: 10%;
    }

    @include media-breakpoint-up(sm) {
      width: 50%;
      padding-top: 203px;
    }

    @include media-breakpoint-up(md) {

    }

    .heading {
      margin-bottom: 33px;
    }

    .subheading {
      margin-bottom: 29px;
    }

    .paragraph {
      width: 227px;
      max-width: 100%;
      margin-bottom: 305px;

      @include media-breakpoint-up(sm) {
        width: 445px;
        margin-bottom: 95px;
      }
    }

    .btn {
      display: block;
      width: 303px;
      max-width: 100%;
      padding-right: 0;
      padding-left: 0;
      margin: 0 auto;

      @include media-breakpoint-down(md) {
        padding: 13px 0;
      }

      @include media-breakpoint-up(md) {
        margin: 0;
      }
    }
  }

  &__right {
    @include media-breakpoint-up(sm) {
      display: flex;
      width: 50%;
      flex: 0 0 auto;
      align-items: center;
      //justify-content: center;
    }

    img {
      position: absolute;
      bottom: -140px;
      left: 59%;
      width: 480px;
      max-width: inherit;
      transform: translateX(-50%);

      @include media-breakpoint-up(sm) {
        position: static;
        width: auto;
        margin-left: auto;
        transform: none;
      }

      @include media-breakpoint-up(lg) {
        margin-right: -75px;
      }
    }
  }
}