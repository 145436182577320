.manifest {
    position: relative;
    height: 1275px;
    background: $black;

    @include media-breakpoint-up(sm) {
        height: 1500px;
    }

    @include media-breakpoint-up(md) {
        height: 1720px;
    }

    &__player {
        position: fixed;
        z-index: -200;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.8);
        opacity: 0;
        iframe {
            position:absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        .manifest-video-wrapper {
            position: relative;
            max-width: calc(1.7778 * 100vh);
            height: 0;
            padding-bottom: 100vh;
            margin: auto;
        }


    }

    &--playing {
        //height: 100vh;
        background-color: transparent;

        .manifest__player {
            z-index: 2000;
            opacity: 1;
        }

        //.manifest__parallax, picture {
        //    opacity: 0.6;
        //}
    }

    &__parallax {
        position: absolute;
        top: 0;
        left: 50%;
        overflow: hidden;
        height: 100%;
        text-align: center;
        transform: translateX(-50%);

        img {
            width: 576px;
            max-width: inherit;

            @include media-breakpoint-up(xs) {
                width: 769px;
            }

            @include media-breakpoint-up(sm) {
                width: 1200px;
            }

            @include media-breakpoint-up(md) {
                width: 1440px;
            }

            @include media-breakpoint-up(lg) {
                width: 1666px;
            }

            @include media-breakpoint-up(xl) {
                width: auto;
            }
        }
    }

    .container {
        position: relative;
        padding: 147px 0 170px;

        @include media-breakpoint-down(xs) {
            padding-right: 0;
            padding-left: 0;
        }

        @include media-breakpoint-up(lg) {
            padding-top: 265px;
            padding-bottom: 0;
        }
    }

    &__image {
        position: absolute;

        @include media-breakpoint-up(xs) {
            width: 320px;
        }

        @include media-breakpoint-up(md) {
            width: 520px;
        }

        @include media-breakpoint-up(lg) {
            width: auto;
        }

        img {
            @include media-breakpoint-down(xs) {
                transform: none !important;
            }
        }

        &--1 {
            top: 0;
            right: 0;

            @include media-breakpoint-up(sm) {
                right: initial;
                left: 0;
            }
        }

        &--2 {
            top: 361px;
            left: 0;

            @include media-breakpoint-up(sm) {
                top: 180px;
                right: 0;
                left: initial;
                text-align: right;
            }
        }

        &--3 {
            top: 722px;
            right: 0;

            @include media-breakpoint-up(xs) {
                bottom: 0;
            }

            @include media-breakpoint-up(sm) {
                top: initial;
                right: initial;
                left: 50%;
                width: auto;
                transform: translateX(-50%);
            }

            @include media-breakpoint-up(md) {
                bottom: -120px;
            }

            @include media-breakpoint-up(lg) {
                bottom: -300px;
            }

            @include media-breakpoint-up(xl) {
                bottom: -150px;
            }

            img {
                @include media-breakpoint-up(sm) {
                    max-width: inherit;
                }
            }
        }
    }

    &__row {
        margin-bottom: 200px;

        @include media-breakpoint-up(xs) {
            padding: 0 20px 0 10%;
        }

        @include media-breakpoint-up(lg) {
            margin-bottom: 160px;
        }

        &--2,
        &--4 {
            @include media-breakpoint-up(sm) {
                padding-left: 30%;
            }

            @include media-breakpoint-up(lg) {
                padding-left: 46%;
            }

            @include media-breakpoint-up(xl) {
                padding-left: 48%;
            }
        }

        &--3 {
            display: none;

            @include media-breakpoint-up(sm) {
                display: block;
            }
        }

        &--4 {
            margin-bottom: 0;
        }
    }

    &__text {
        position: relative;
        max-width: 283px;
        margin: 0 auto;
        font-size: 1.667em;
        line-height: 1.333em;
        text-align: center;

        @include media-breakpoint-up(sm) {
            max-width: inherit;
            font-size: 2.222em;
            line-height: 1.433em;
            text-align: left;
        }

        @include media-breakpoint-up(md) {
            font-size: 2.778em;
        }

        @include media-breakpoint-up(xl) {
            font-size: 3.333em;
        }

        strong {
            font-family: $font-family-secondary;
            font-weight: 700;
        }

        br {
            display: none;
            @include media-breakpoint-up(md) {
                display: inherit;
            }
        }
    }

    &__play {
        position: absolute;
        bottom: 38px;
        left: 50%;
        display: none;
        padding: 0;
        border: 0;
        background: none;
        color: $white;
        font-family: $font-family-secondary;
        font-size: 1.167em;
        font-weight: 700;
        letter-spacing: 0.84px;
        text-transform: uppercase;
        transform: translateX(-50%);

        @include media-breakpoint-up(sm) {
            right: 36px;
            bottom: 450px;
            left: initial;
            display: block;
        }

        @include media-breakpoint-up(md) {
            right: 74px;
        }

        @include media-breakpoint-up(lg) {
            bottom: 300px;
        }

        @include media-breakpoint-up(xl) {
            bottom: 404px;
        }

        span,
        svg {
            display: inline-block;
            vertical-align: middle;
        }

        span {
            @include media-breakpoint-up(sm) {
                display: none;
            }
        }

        svg {
            position: absolute;
            top: 50%;
            right: -37px;
            transform: translate(100%, -50%);

            @include media-breakpoint-up(sm) {
                position: static;
                transform: none;
            }

            &,
            image {
                width: 30px;
                height: 34px;

                @include media-breakpoint-up(sm) {
                    width: initial;
                    height: initial;
                }
            }
        }

        &:hover {
            svg {
                opacity: .8;
            }
        }

        &--mobile {
            display: block;

            @include media-breakpoint-up(sm) {
                display: none;
            }
        }
    }
}
