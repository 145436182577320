.footer {
  position: relative;
  z-index: 5000;
  background: #888b8f;
  color: #fff;
}
.footer__container {
  display: grid;
  max-width: 136.6rem;
  padding: 6.4rem 6.4rem 6rem;
  margin: 0 auto;
  column-gap: 3.2rem;
  grid-template-columns: repeat(12, 1fr);
}
@media screen and (min-width: 1024px) and (min-aspect-ratio: 2/1) {
  .footer__container {
    max-width: unset;
  }
  .footer__container.benefits {
    max-width: 136.6rem;
  }
}
@media screen and (max-width: 1023px) {
  .footer__container {
    padding-right: 3.2rem;
    padding-left: 3.2rem;
  }
}
@media screen and (max-width: 767px) {
  .footer__container {
    padding-right: 2.4rem;
    padding-left: 2.4rem;
  }
}
@media screen and (max-width: 1023px) {
  .footer__container {
    column-gap: 2.4rem;
    grid-template-columns: repeat(8, 1fr);
  }
}
@media screen and (max-width: 767px) {
  .footer__container {
    grid-template-columns: repeat(6, 1fr);
  }
}
@media screen and (max-width: 1023px) {
  .footer__container {
    padding-top: 8.6rem;
    padding-bottom: 6.4rem;
    row-gap: 13.2rem;
  }
}
@media screen and (max-width: 767px) {
  .footer__container {
    padding-top: 6.9rem;
    padding-bottom: 2.4rem;
    row-gap: 4.8rem;
  }
}
.footer__copyrights {
  grid-column: 1/3;
}
@media screen and (max-width: 767px) {
  .footer__copyrights {
    grid-column: 1/4;
  }
}
.footer__privacy {
  grid-column: 3/5;
}
@media screen and (max-width: 767px) {
  .footer__privacy {
    grid-column: 5/7;
  }
}
.footer__copyrights,
.footer__privacy {
  margin-top: auto;
  font-family: Futura-PT, arial, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.01em;
  line-height: 1.2;
  text-transform: uppercase;
}
@media screen and (max-width: 1023px) {
  .footer__copyrights,
  .footer__privacy {
    grid-row: 2;
  }
}
@media screen and (max-width: 767px) {
  .footer__copyrights,
  .footer__privacy {
    margin-top: 1.6rem;
    grid-row: 3;
  }
}
.footer__info {
  grid-column: 6/8;
}
@media screen and (max-width: 1023px) {
  .footer__info {
    grid-column: 1/3;
    grid-row: 1;
  }
}
@media screen and (max-width: 767px) {
  .footer__info {
    grid-column: 1/7;
    grid-row: 2;
  }
}
.footer__contact {
  grid-column: 9/13;
}
@media screen and (max-width: 1023px) {
  .footer__contact {
    grid-column: 4/8;
    grid-row: 1;
  }
}
@media screen and (max-width: 767px) {
  .footer__contact {
    grid-column: 1/7;
  }
}
.footer__contact a {
  display: inline-block;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.footer__contact,
.footer__info {
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 1.3;
}
@media screen and (max-width: 767px) {
  .footer__contact,
  .footer__info {
    font-size: 1.6rem;
    font-size: 2rem;
    line-height: 1.2;
  }
}
.footer a {
  width: -moz-fit-content;
  width: fit-content;
  transition: color 0.15s ease;
}
.no-touch .footer a:hover {
  color: #eae0dc;
}
.footer-label {
  display: inline-block;
  margin-bottom: 0.8rem;
  color: #eae0dc;
  font-family: Futura-PT, arial, sans-serif;
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0.01em;
  line-height: 1.2;
  text-transform: uppercase;
}
