.background {
  position: fixed;
  z-index: -1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  //background: url('/public/images/bg-mobile.jpg') no-repeat 0 0 transparent;
  background-size: cover;

  @include media-breakpoint-up(lg) {
    //background-image: url('/public/images/bg.jpg');
  }
}
