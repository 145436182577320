.body-block {
  overflow: hidden;
  height: 100%;

  @include media-breakpoint-up(md) {
    overflow: auto;
    height: auto;
  }
}

.container {
  @include container-main();
}

.btn {
  font-family: $font-family-secondary;
  font-weight: bold;
  letter-spacing: 0.84px;
  text-transform: uppercase;

  span {
    display: inline-block;
    vertical-align: middle;
  }

  &-default {
    border: 1px solid $white;

    &:hover {
      background: $white;
      color: $primary;
    }
  }

  &-white {
    background: $white;
    color: $primary;

    &:hover {
      color: $primary;
      opacity: .9;
    }
  }

  &-buy {
    position: relative;

    svg {
      position: absolute;
      top: 50%;
      right: 18px;
      width: 24px;
      height: 20px;
      transform: translateY(-50%);

      @include media-breakpoint-up(md) {
        right: 28px;
      }
    }
  }
}

.heading {
  color: rgba($white, .5);
  font-size: 1.667em;
  letter-spacing: 0.14px;
  line-height: 1.333em;

  @include media-breakpoint-up(md) {
    font-size: 2em;
  }

  @include media-breakpoint-up(xl) {
    font-size: 3.333em;
    line-height: 1.433em;
  }
}

.subheading {
  font-size: 1.667em;
  letter-spacing: 0.14px;
  line-height: 1.333em;

  @include media-breakpoint-up(md) {
    font-size: 2em;
  }

  @include media-breakpoint-up(xl) {
    font-size: 3.333em;
    line-height: 1.433em;
  }

  strong {
    font-family: $font-family-secondary;
  }
}

.paragraph {
  letter-spacing: 0.04px;
  line-height: 1.444em;

  @include media-breakpoint-up(md) {
    font-size: 1.25em;
    letter-spacing: 0.06px;
    line-height: 1.583em;
  }

  @include media-breakpoint-up(xl) {
    font-size: 1.333em;
  }
}

.percentage {
  display: block;
  font-size: 2em;

  @include media-breakpoint-up(md) {
    margin-bottom: 7px;
    font-size: 3.333em;
  }

  &::after {
    display: inline-block;
    margin-left: 4px;
    content: '%';
    font-size: 0.778rem;

    @include media-breakpoint-up(md) {
      font-size: 1.333rem;
    }
  }
}