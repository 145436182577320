






















































































































































































































.tracking-history {
  padding: 55px 0 47px;
  .tracking-item {
    padding-bottom: 40px;
    &:last-child {
      padding-bottom: 0;
    }
  }
  .content {
    max-width: 874px;
    margin: auto;
  }
  .tracking-tile {
    width: 100%;
    padding: 0 28px 0 32px;
    background-color: #ffffff;
    box-shadow: 0 0 22px 0 rgba(0, 0, 0, 0.07);
  }
  .order-info {
    display: flex;
    padding-bottom: 4px;
    position: relative;
    &::before {
      position: absolute;
      left: 0;
      bottom: 0;
      content: "";
      width: calc(100% + 60px);
      margin-left: -32px;
      height: 1px;
      background-color: rgba(#979797, 0.14);
    }
    > div {
      width: calc(100% / 3);
      padding: 21px 33px 38px;
      border-right: 1px solid rgba(#979797, 0.14);
      &:first-child {
        padding-left: 0;
      }
      &:nth-child(2) {
        padding-top: 45px;
      }
      &:last-child {
        padding-top: 45px;
        border: none;
      }
    }
  }
  p {
    color: var(--dark);
    font-size: 14px;
    line-height: (18/14);
  }
  .number {
    color: var(--dark);
    font-size: 15px;
    font-weight: 600;
    line-height: (19/15);
  }
  .latest-status {
    margin-top: 26px;
    display: flex;
    .icon-wrap {
      height: 32px;
    }
    img {
      filter: brightness(0) invert(1);
    }
  }
  .status-text {
    margin-left: 8px;
  }
  .status {
    font-size: 12px;
    font-weight: 700;
    line-height: (15/12);
    margin-bottom: 5px;
  }
  .last-update {
    color: rgba(51, 51, 51, 0.5);
    font-size: 10px;
    line-height: (13/10);
  }
  .label {
    color: rgba(51, 51, 51, 0.5);
    font-size: 10px;
    letter-spacing: 0;
    line-height: (13/10);
    margin-bottom: 6px;
  }
  .details-label {
    font-size: 18px;
    letter-spacing: 0;
    line-height: (23/18);
    color: var(--primary);
    padding-top: 30px;
  }
  .icon-wrap {
    width: 32px;
    height: 44px;
    border-radius: 50%;
    background-color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    flex-shrink: 0;

    &::before {
      position: absolute;
      content: "";
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 32px;
      height: 32px;
      border: 1px solid var(--blue);
      z-index: -1;
      border-radius: 50%;
    }
    &.active {
      &::before {
        background-color: var(--blue);
      }
    }
    img {
      position: relative;
      width: 28px;
      height: auto;
    }
  }
  .order-status {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 17px 0 25px;
    position: relative;
    &:last-child {
      &::before {
        display: none;
      }
    }
    &::before {
      position: absolute;
      content: "";
      top: 20px;
      left: 16px;
      width: 1px;
      height: 100%;
      background-color: var(--blue);
    }
    &::after {
      position: absolute;
      content: "";
      bottom: 0;
      right: 0;
      background-color: rgba(#979797, 0.14);
      width: calc(100% - 52px);
      height: 1px;
    }
  }
  .status-type {
    display: flex;
    flex-shrink: 0;
    margin-right: 30px;
  }
  .status-type-text {
    margin-left: 16px;
  }
  .status-label {
    padding-top: 5px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 16px;
    line-height: (24/16);
    margin-bottom: 4px;
  }
  .substatus {
    color: rgba(0, 0, 0, 0.65);
    font-size: 12px;
    margin-bottom: 4px;
  }
  .status-info {
    font-size: 14px;
    line-height: (20/14);
    color: rgba(0, 0, 0, 0.65);
    text-align: right;
  }
  time {
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    line-height: (20/14);
  }
  strong {
    font-weight: 700;
  }
  .toggle-details {
    color: var(--dark);
    font-size: 12px;
    font-weight: 600;
    line-height: (15/12);
    text-align: center;
    padding: 20px 0 24px;
    cursor: pointer;
    &.opened {
      span {
        &::before {
          transform: rotate(-135deg);
          top: 3px;
        }
      }
    }
    span {
      position: relative;
      &::before {
        position: absolute;
        content: "";
        top: -3px;
        bottom: 0;
        right: -11px;
        margin: auto 0;
        width: 6px;
        height: 6px;
        border-bottom: 2px solid var(--dark);
        border-right: 2px solid var(--dark);
        transform: rotate(45deg);
      }
    }
  }
  .no-order {
    color: var(--dark);
    font-size: 15px;
    font-weight: 600;
    line-height: 1.26667;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 34px;
      filter: invert(21%) sepia(96%) saturate(7500%) hue-rotate(359deg)
        brightness(115%) contrast(112%);
    }
  }
  @media screen and (max-width: 767px) {
    .order-info {
      flex-direction: column;
      > div {
        width: 100%;
        border-right: none;
        padding: 15px 0px 15px;
        &:first-child {
          display: flex;
          align-items: center;
        }
        &:nth-child(2) {
          padding-top: 15px;
        }
        &:last-child {
          padding-top: 15px;
        }
      }
      .tracking-no-text {
        order: 1;
      }
      .latest-status {
        margin-right: 20px;
        margin-top: 0;
      }
      .status-info {
        max-width: 360px;
      }
    }
  }
  @media screen and (max-width: 460px) {
    .order-status {
      flex-direction: column;
      padding: 9px 0 15px;
    }
    .status-type {
      width: 100%;
      margin: 0;
    }
    .status-info {
      width: 100%;
      text-align: left;
      margin-top: 10px;
      padding-left: 49px;
    }
  }
}
