.main-navigation {
  @include list-unstyled();
  overflow: auto;
  width: 100%;
  max-height: 100%;
  text-align: center;

  @include media-breakpoint-up(md) {
    display: flex;
    justify-content: space-between;
  }

  &__link {
    position: relative;
    display: inline-block;
    padding-bottom: 5px;
    margin: 21px 0;
    font-size: 1.167em;
    font-weight: 700;
    text-transform: uppercase;

    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 2px;
      background: $white;
      content: '';
      transition: width .2s ease-in-out;
    }

    &:hover {
      &::after {
        width: 100%;
      }
    }
  }
}