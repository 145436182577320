.products {
  padding-top: 39px;

  @include media-breakpoint-up(md) {
    padding: 90px 0 63px;
  }

  .container {
    @include media-breakpoint-down(xs) {
      padding: 0;
    }
  }

  &__slider {
    width: 100%;

    .swiper-slide {
      position: relative;
      min-height: 655px;

      @include media-breakpoint-up(md) {
        display: flex;
        min-height: 887px;
      }
    }
  }


  &__inner {
    width: 100%;

    @include media-breakpoint-down(xs) {
      padding-right: 20px;
      padding-left: 20px;
    }

    @include media-breakpoint-up(md) {
      display: flex;
    }

    &--product {
      .products__right,
      .products__description {
        transition: transform .3s ease-in-out;
      }

      .products__description {
        max-width: 69%;
        margin-right: 0;
        margin-left: 0;
        text-align: left;

        @include media-breakpoint-up(xs) {
          max-width: 63%;
          margin-top: 15%;
        }

        @include media-breakpoint-up(sm) {
          margin-top: 10%;
        }

        @include media-breakpoint-up(md) {
          max-width: inherit;
          padding-top: 4%;
        }

        @include media-breakpoint-up(xl) {
          margin-top: 0;
        }

        br {
          @include media-breakpoint-down(xs) {
            display: none;
          }
        }
      }

      .products__right {
        position: absolute;
        top: 50px;
        left: 0;
        width: 100%;
        transform: translateX(41%);

        @include media-breakpoint-up(xs) {
          top: 40px;
          transform: translateX(32%);
        }

        @include media-breakpoint-up(sm) {
          top: 20px;
        }

        @include media-breakpoint-up(md) {
          position: relative;
          width: 50%;
          transform: none;
        }

        img {
          width: 240px;


          @include media-breakpoint-up(xs) {
            width: 250px;
          }

          @include media-breakpoint-up(sm) {
            transform: translateY(9%);
          }

          @include media-breakpoint-up(md) {
            width: auto;
            transform: none;
          }
        }
      }

      .products__property {
        opacity: 0;
        transition: opacity .3s ease-in;
        transition-delay: .2s;

        @include media-breakpoint-up(sm) {
          opacity: 1;
        }
      }

      &:active {
        .products__description {
          transform: translateX(-120%);

          @include media-breakpoint-up(md) {
            transform: none;
          }
        }

        .products__right {
          transform: translateX(-2%);

          @include media-breakpoint-up(md) {
            transform: none;
          }

          img {
            transform: translateX(11%);

            @include media-breakpoint-up(md) {
              transform: none;
            }
          }
        }

        .products__property {
          opacity: 1;
        }
      }
    }
  }

  &__description {
    max-width: 254px;
    margin: 0 auto 26px;
    text-align: center;

    @include media-breakpoint-up(md) {
      width: 50%;
      max-width: inherit;
      padding: 12% 0 0 10%;
      margin-right: 0;
      margin-left: 0;
      text-align: left;
    }

    .heading {
      margin-bottom: 20px;

      @include media-breakpoint-up(md) {
        margin-bottom: 33px;
      }
    }

    .subheading {
      margin-bottom: 20px;

      @include media-breakpoint-up(md) {
        margin-bottom: 33px;
      }
    }
  }

  &__right {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 22px;
    text-align: center;

    @include media-breakpoint-up(md) {
      width: 50%;
    }

    img {
      position: relative;
      width: 300px;
      transition: transform .2s ease-in-out;

      @include media-breakpoint-up(sm) {
        width: 280px;
      }

      @include media-breakpoint-up(md) {
        width: auto;
      }
    }
  }

  &__property {
    position: absolute;
    display: flex;
    width: 109px;
    height: 109px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid $white;
    border-radius: 50%;
    font-size: 0.556em;
    text-align: center;

    @include media-breakpoint-up(md) {
      width: 180px;
      height: 180px;
      font-size: 0.889em;
    }

    &-top,
    &-bottom {
      position: absolute;
      left: 50%;
      width: 100%;
      transform: translateX(-50%);
    }

    &-top {
      top: 20px;

      @include media-breakpoint-up(md) {
        top: 31px;
      }
    }

    &-bottom {
      bottom: 20px;

      @include media-breakpoint-up(md) {
        bottom: 31px;
      }
    }

    &--1 {
      top: 5%;
      right: 50%;

      @include media-breakpoint-up(md) {
        right: 60%;
      }
    }

    &--2 {
      right: 2%;
      bottom: 11%;

      @media (min-width: 400px) {
        right: 12%;
      }

      @media (min-width: 520px) {
        right: 20%;
      }

      @include media-breakpoint-up(xs) {
        right: 24%;
      }

      @include media-breakpoint-up(sm) {
        right: 29%;
      }

      @include media-breakpoint-up(md) {
        right: 0;
      }

      @include media-breakpoint-up(lg) {
        right: 12%;
      }
    }
  }

  &__navigation {
    position: absolute;
    bottom: 20px;
    left: 50%;
    display: flex;
    width: 100%;
    height: 59px;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    transform: translateX(-50%);

    @include media-breakpoint-up(md) {
      bottom: 75px;
      left: 10%;
      width: auto;
      height: 88px;
      transform: none;
    }

    p {
      margin: 0 27px;
      font-family: $font-family-secondary;
      font-size: 1.167em;
      text-align: center;
      text-transform: uppercase;

      @include media-breakpoint-up(xs) {
        margin: 0 36px;
      }
    }

    .btn {
      margin: 0 20px;

      @include media-breakpoint-down(sm) {
        width: auto;
        padding: 0;
        border: none;
        background: none;
        color: $white;

        svg {
          display: none;
        }
      }

      @include media-breakpoint-up(md) {
        width: 303px;
        padding-right: 0;
        padding-left: 0;
        text-align: center;
      }
    }
  }

  &__arrow {
    height: 100%;
    padding: 0 7px;
    border: 1px solid $white;
    background: none;

    @include media-breakpoint-up(md) {
      padding: 0 49px;
    }

    svg {
      position: relative;

      @include media-breakpoint-up(md) {
        position: static;
      }
    }

    &:hover {
      @include media-breakpoint-up(md) {
        opacity: .6;
      }
    }

    &--disabled {
        opacity: .6;
    }

    &--prev {
      svg {
        right: -17px;
      }
    }

    &--next {
      svg {
        left: -17px;
      }
    }
  }

}