.start {
  position: relative;
  padding-top: 4px;

  @include media-breakpoint-up(sm) {
    padding-top: 35px;
  }

  @include media-breakpoint-up(lg) {
    padding-top: 86px;
  }

  .container {
    @include media-breakpoint-down(xs) {
      padding: 0;
    }
  }

  &__bg {
    position: relative;
    display: block;
    width: 993px;
    max-width: 100%;
    margin: 0 auto;

    img {
      display: block;
      margin: 0 auto 25px;

      @include media-breakpoint-up(sm) {
        margin-bottom: 0;
      }
    }
  }

  &__subheading {
    position: relative;
    display: block;
    margin: 0 auto;
    background: none;
    font-size: 1.167em;
    text-align: center;

    @include media-breakpoint-up(sm) {
      position: absolute;
      right: 7%;
      bottom: 32%;
      font-size: 1.333em;
    }

    svg {
      display: block;
      margin: 15px auto 18px;

      @include media-breakpoint-up(sm) {
        display: none;
      }
    }
  }

  &__scroll {
    position: absolute;
    top: 40%;
    right: $grid-gutter-width;
    display: none;
    width: 180px;
    height: 180px;
    border: 1px solid $white;
    border-radius: 50%;
    transform: translateY(-50%);

    @include media-breakpoint-up(lg) {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:hover {
      border-color: rgba($white, .7);
    }
  }
}