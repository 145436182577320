@font-face {
  font-family: "SourceSansPro";
  font-style: normal;
  font-weight: 400;
  src: url("/public/fonts/sourcesanspro/sourcesanspro-regular.woff")
    format("woff");
}

@font-face {
  font-family: "SourceSansPro";
  font-style: italic;
  font-weight: 400;
  src: url("/public/fonts/sourcesanspro/sourcesanspro-italic.woff") format("woff");
}

@font-face {
  font-family: "SourceSansPro";
  font-style: normal;
  font-weight: 600;
  src: url("/public/fonts/sourcesanspro/sourcesanspro-semibold.woff")
    format("woff");
}

@font-face {
  font-family: "SourceSansPro";
  font-style: italic;
  font-weight: 600;
  src: url("/public/fonts/sourcesanspro/sourcesanspro-semibolditalic.woff")
    format("woff");
}

@font-face {
  font-family: "SourceSansPro";
  font-style: normal;
  font-weight: 700;
  src: url("/public/fonts/sourcesanspro/sourcesanspro-bold.woff") format("woff");
}

@font-face {
  font-family: "SourceSansPro";
  font-style: italic;
  font-weight: 700;
  src: url("/public/fonts/sourcesanspro/sourcesanspro-bolditalic.woff")
    format("woff");
}
