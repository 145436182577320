.about {
  position: relative;
  overflow: hidden;
  padding: 75px 0 139px;
  //background: url('/public/images/about-bg.jpg') no-repeat center bottom $black;

  @include media-breakpoint-up(sm) {
    padding: 84px 0 174px;
  }

  .container {
    @include media-breakpoint-down(xs) {
      padding-right: 30px;
    }

    @include media-breakpoint-up(md) {
      display: flex;
    }
  }

  &__left {
    position: relative;
    z-index: 10;

    @include media-breakpoint-up(xs) {
      padding: 9% 25px 0 10%;
    }

    .heading {
      margin-bottom: 33px;

      @include media-breakpoint-up(md) {
        margin-bottom: 40px;
      }

      @include media-breakpoint-up(xl) {
        margin-bottom: 76px;
      }
    }

    .subheading {
      margin-bottom: 33px;

      @include media-breakpoint-up(xl) {
        margin-bottom: 51px;
      }
    }
  }

  &__right {
    display: flex;
    width: 39%;
    flex: 0 0 auto;
    align-items: center;
    justify-content: center;

    img {
      position: absolute;
      right: -15%;
      bottom: 41px;

      @include media-breakpoint-up(md) {
        position: static;
      }
    }
  }
}
