#age-consent {
  position: fixed;
  z-index: 9999;
  top: -200px;
  bottom: -200px;
  left: 0;
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 200px 0;
  text-align: center;
  transition: opacity 0.5s;

  .container {
    width: 500px;
  }

  img {
    width: 134px;
    margin-bottom: 50px;

    @include media-breakpoint-up(md) {
      width: 200px;
    }

    @include media-breakpoint-up(lg) {
      width: 250px;
    }

    @include media-breakpoint-up(xl) {
      width: 315px;
    }
  }

  .subheading {
    margin-bottom: 50px;

    @include media-breakpoint-down(xs) {
      font-size: 1.125em;
    }
  }
}