@mixin container-main() {
  @include make-container();
  width: 1440px;
  max-width: 100%;
  padding: 0 119px 0 148px;

  @include media-breakpoint-down(sm) {
    padding: 0 50px 0 50px;
  }

  @include media-breakpoint-down(xs) {
    padding: 0 20px 0 20px;
  }

  &--narrow {
    width: 843px;
  }
}

@mixin toggle() {
  position: relative;
  display: block;
  width: 21px;
  height: 18px;
  padding: 0;

  @include media-breakpoint-up(md) {
    display: none;
  }

  &::before {
    display: none;
  }

  span {
    position: relative;
    display: block;
    overflow: visible;

    &,
    &::after,
    &::before {
      top: 0;
      width: 20px;
      height: 2px;
      background: $white;
      border-radius: 4px;
    }

    &::after,
    &::before {
      position: absolute;
      left: 0;
      content: "";
      transition: transform 0.1s;
    }

    &::before {
      top: -7px;
      transform: rotate(0);
    }

    &::after {
      top: 7px;
      transform: rotate(0);
    }
  }

  &.toggle--close,
  &.toggle--active {
    width: 35px;
    height: 32px;

    span {
      & {
        background: transparent;
      }

      &::before,
      &::after {
        width: 40px;
      }

      &::before {
        top: 0;
        transform: rotate(-45deg);
      }

      &::after {
        top: 0;
        transform: rotate(45deg);
      }
    }
  }
}

@mixin list-unstyled() {
  padding-left: 0;
  margin: 0;
  list-style: none;
}
