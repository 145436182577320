.navbar {
  position: fixed;
  z-index: $zindex-sticky;
  top: 0;
  left: 0;
  width: 100%;
  padding: 38px 0;
  font-family: $font-family-secondary;
  letter-spacing: 0.84px;
  transition: background-color 0.2s;

  @include media-breakpoint-up(md) {
    padding: 35px 0;
  }

  &--sticked {
    background: rgba($black, 0.6);

    .navbar__right {
      opacity: 0;
      pointer-events: none;
    }
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__logo {
    display: block;
    width: 134px;
    margin: 0 auto;

    @include media-breakpoint-up(md) {
      width: 200px;
      margin: 0;
    }

    @include media-breakpoint-up(lg) {
      width: 250px;
    }

    @include media-breakpoint-up(xl) {
      width: 315px;
    }
  }

  &__nav {
    @extend .background;
    position: fixed;
    z-index: $zindex-modal-backdrop;
    top: -200px;
    bottom: -200px;
    left: 0;
    display: none;
    width: 100%;
    align-items: center;
    padding: 200px 0;

    @include media-breakpoint-up(md) {
      position: static;
      display: flex;
      max-width: 675px;
      padding: 0;
      margin: 0 auto;
      background: none;
    }

    @include media-breakpoint-up(lg) {
      max-width: 720px;
    }

    &--active {
      display: flex;
    }
  }

  &__toggle {
    @include toggle();
    position: absolute;
    z-index: $zindex-modal;
    top: 44px;
    right: 35px;
    border: 0;
    background: none;
  }

  &__right {
    display: none;
    transition: opacity 0.25s ease-in-out;

    @include media-breakpoint-up(lg) {
      display: block;
      text-transform: uppercase;
    }
  }
}
