/* base */
@import "fonts";
@import "variables";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/utilities/display";
@import "~bootstrap/scss/utilities/text";
@import "~bootstrap/scss/alert";
@import "mixins";
@import "base";
@import "utils/common";
@import "utils/helpers";
//@import "pixel-perfect";
/* base end */

@import "~swiper/swiper-bundle";

@import "components/age-consent";
@import "components/background";
@import "components/navbar";
@import "components/main-navigation";
@import "components/start";
@import "components/manifest";
@import "components/products";
@import "components/about";
@import "components/merch";
@import "components/privacy-policy";
@import "components/footer";
@import "components/relacje";

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.container {
  position: relative;
}

header {
  padding: 19px 20px 14px;
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);

  .logo-wrap {
    display: block;
    width: 113px;
    margin: auto;
  }
  img {
    width: 100%;
  }
}

main {
  position: relative;
}

.lottie-animation {
  &.jetpack {
    position: absolute;
    top: 0;
    right: 0;
    width: 20vw;
  }
  &.truck {
    margin-bottom: -5px;
  }
  @media screen and (max-width: 1400px) {
    &.jetpack {
      width: 15vw;
    }
  }
}
